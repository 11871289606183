import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Restricted from '../../PermissionProvider/Restricted';

const MainMenu = ({ params }) => {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    console.log('MainMenu useEffect');
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const [displayMasterData, toggleMasterData] = useState(false);
  const [displayLesson, toggleLesson] = useState(false);
  const [displayAuth, toggleAuthentication] = useState(false);
  const [displayScriptantia, setDisplayScriptantia] = useState(true);
  const [displayPalanta, togglePalanta] = useState(true);
  const [displayTananyagok, toggleTananyagok] = useState(true);

  const toggleScriptantia = (value) => {
    setDisplayScriptantia(value);
  };

  const [displayScriptantiaMasterDate, toggleScriptantiaMasterDate] = useState(
    params.parameters.TORZSADATOK_COLLAPSED === '0'
  );
  const [displayScriptantiaMovements, toggleScriptantiaMovements] = useState(
    params.parameters.INVOICE_MENU_COLLAPSED === '0'
  );

  const [displayScriptantiaEducation, toggleScriptantiaEducation] = useState(
    params.parameters.OKTATAS_COLLAPSED === '0'
  );
  const [displayScriptantiaPayments, toggleScriptantiaPayments] = useState(
    params.parameters.PAYMENTS_COLLAPSED === '0'
  );
  const [displayScriptantiaTasks, toggleScriptantiaTasks] = useState(
    params.parameters.TASKS_COLLAPSED === '0'
  );

  const [displayScriptantiaReports, toggleScriptantiaReports] = useState(
    params.parameters.REPORTS_MENU_COLLAPSED === '0'
  );
  const [displayScriptantiaCampaigns, toggleScriptantiaCampaigns] = useState(
    params.parameters.CAMPAIGNS_MENU_COLLAPSED === '0'
  );

  return (
    <Fragment>
      <div className='w3-card w3-round'>
        <div className='w3-white'>
          <p>Oldal adminisztráció</p>
          <Restricted to='masterData.menu'>
            <button
              onClick={() => toggleMasterData(!displayMasterData)}
              type='button'
              className='w3-button w3-block w3-theme-l1 w3-left-align'
            >
              <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
              Törzsadatok
            </button>
            {displayMasterData && (
              <Fragment>
                <div className='  w3-container'>
                  <div>
                    <Link to='/subjects'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Tantárgyak'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/topics'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Témakörök'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/problems'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Feladatok'}
                    </Link>
                  </div>
                </div>
              </Fragment>
            )}
          </Restricted>
          <Restricted to='training.menu'>
            <button
              onClick={() => toggleLesson(!displayLesson)}
              type='button'
              className='w3-button w3-block w3-theme-indigo w3-left-align'
            >
              <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
              Tananyag szerkesztő
            </button>
            {displayLesson && (
              <Fragment>
                <div className='  w3-container'>
                  <Restricted to='training.menu'>
                    <button
                      onClick={() => toggleTananyagok(!displayTananyagok)}
                      type='button'
                      className='w3-button w3-block w3-theme-indigo-l4 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Feladatok
                    </button>
                    {displayTananyagok && (
                      <Fragment>
                        <div className='  w3-container'>
                          <div>
                            <Link to='/ExerciseCatalogCreate'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Feladat rögzítés'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/HintEditor'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Segítség sablonok'}
                            </Link>
                          </div>
                          <Restricted to='training.approval.menu'>
                            <div>
                              <hr></hr>
                            </div>
                            <div>
                              <Link to='/ExerciseCatalogApprove'>
                                <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                                {' Feladat Jóváhagyás'}
                              </Link>
                            </div>
                          </Restricted>
                          <Restricted to='training.release.menu'>
                            <div>
                              <hr></hr>
                            </div>
                            <div>
                              <Link to='/ExerciseCatalogRelease'>
                                <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                                {' Feladat Élesítés'}
                              </Link>
                            </div>
                          </Restricted>
                        </div>
                      </Fragment>
                    )}
                  </Restricted>
                </div>
              </Fragment>
            )}
          </Restricted>
          <Restricted to='scriptantia.menu'>
            <button
              onClick={() => toggleScriptantia(!displayScriptantia)}
              type='button'
              className='w3-button w3-block w3-theme-dark-orange-l1 w3-left-align'
            >
              <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
              Iskola Adminisztráció
            </button>
            {displayScriptantia && (
              <Fragment>
                <div className='  w3-container'>
                  <Restricted to='scriptantiaEducation.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaEducation(!displayScriptantiaEducation)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Oktatás
                    </button>
                    {displayScriptantiaEducation && (
                      <div className='  w3-container'>
                        <div>
                          <Link to='/Tanora'>
                            <i className='fa fa-users fa-fw w3-margin-right w3-text-theme'></i>
                            {' Tanórák'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/TanoraWizzard'>
                            <i className='fa fa-users   w3-margin-right w3-text-theme'></i>

                            {' Tanóra varázsló'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/scriptantia-timetable'>
                            <i className='fa fa-calendar fa-fw w3-margin-right w3-text-theme'></i>
                            {' Órarend'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/calendar'>
                            <i className='fa fa-calendar fa-fw w3-margin-right w3-text-theme'></i>
                            {' Órarend új'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/OpenHours'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Lezáratlan órák'}
                          </Link>
                        </div>{' '}
                        <div>
                          <hr></hr>
                        </div>
                        <div>
                          <Link to='/createHomework'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Kiosztott házi feladatok'}
                          </Link>
                        </div>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaMaster.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaMasterDate(
                          !displayScriptantiaMasterDate
                        )
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Törzsadatok
                    </button>
                    {displayScriptantiaMasterDate && (
                      <div className='  w3-container'>
                        <Restricted to='scriptantiaMaster.Users'>
                          <div>
                            <Link to='/users'>
                              <i className='fa fa-user fa-fw w3-margin-right w3-text-theme'></i>
                              {' Felhasználók - Scriptantia Admin'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/ManageUsers'>
                              <i className='fa fa-user fa-fw w3-margin-right w3-text-theme'></i>
                              {' Felhasználók - új'}
                            </Link>
                          </div>
                          <div>
                            <hr></hr>
                          </div>
                        </Restricted>
                        <Restricted to='scriptantiaMaster.Settings'>
                          <div>
                            <Link to='/MasterData'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Alapadatok'}
                            </Link>
                          </div>
                          <div>
                            <hr></hr>
                          </div>
                          <div>
                            <Link to='/registrations'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Online regisztrációk'}
                            </Link>
                          </div>
                        </Restricted>
                        <Restricted to='scriptantiaMaster.discounts'>
                          <div>
                            <Link to='/diak-discounts'>
                              <i className='fa fa-percent fa-fw w3-margin-right w3-text-theme'></i>
                              {' Diák kedvezmények'}
                            </Link>
                          </div>
                        </Restricted>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaTasks.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaTasks(!displayScriptantiaTasks)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Feladatok
                    </button>
                    {displayScriptantiaTasks && (
                      <div className='  w3-container'>
                        <Restricted to='tasks.system'>
                          <div>
                            <Link to='/system-tasks'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Rendszer feladatok'}
                            </Link>
                          </div>
                        </Restricted>
                        <div>
                          <Link to='/my-tasks'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Saját feladataim'}
                          </Link>
                        </div>
                        <Restricted to='communication.sms'>
                          <div>
                            <Link to='/sms-list'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' SMS jóváhagyás'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/sms-create'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' SMS küldés'}
                            </Link>
                          </div>
                        </Restricted>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaPayments.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaPayments(!displayScriptantiaPayments)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Be- és Kifizetések
                    </button>
                    {displayScriptantiaPayments && (
                      <div className='  w3-container'>
                        <div>
                          <Link to='/StudentPayements'>
                            <i className='fa fa-cash-register fa-fw w3-margin-right w3-text-theme'></i>
                            {' Diák befizetések'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/CouponUseage'>
                            <i className='fa fa-tag fa-fw w3-margin-right w3-text-theme'></i>
                            {' Kupon felhasználás'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/CouponSell'>
                            <i className='fa fa-tag fa-fw w3-margin-right w3-text-theme'></i>
                            {' Kupon értékesítés'}
                          </Link>
                        </div>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaMovements.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaMovements(!displayScriptantiaMovements)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Pénzügyek
                    </button>
                    {displayScriptantiaMovements && (
                      <div className='  w3-container'>
                        <Restricted to='scriptantiaInvoicing.menu'>
                          <div>
                            <Link to='/invoice-generate'>
                              <i className='fa fa-file-invoice fa-fw w3-margin-right w3-text-theme'></i>
                              {' Számla készítés'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/invoice-manual'>
                              <i className='fa fa-file-invoice fa-fw w3-margin-right w3-text-theme'></i>
                              {' Kézi számla készítés'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/invoice-discounts'>
                              <i className='fa fa-percent fa-fw w3-margin-right w3-text-theme'></i>
                              {' Számla kedvezmények'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/prepare-kedvezmeny'>
                              <i className='fa fa-percent fa-fw w3-margin-right w3-text-theme'></i>
                              {' Automatikus kedvezmények jóváhagyása'}
                            </Link>
                          </div>

                          <div>
                            <hr></hr>
                          </div>
                        </Restricted>
                        <div>
                          <Link to='/StudentPayements'>
                            <i className='fa fa-cash-register fa-fw w3-margin-right w3-text-theme'></i>
                            {' Diák befizetések'}
                          </Link>
                        </div>
                        <Restricted to='scriptantiaInvoicing.menu'>
                          <div>
                            <Link to='/StudentLoss'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Veszteségleírás'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/Hianyzasok'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Hiányzások kezelése'}
                            </Link>
                          </div>
                        </Restricted>
                        <div>
                          <hr></hr>
                        </div>

                        <div>
                          <Link to='/TeacherPayments'>
                            <i className='fa fa-money-bill fa-fw w3-margin-right w3-text-theme'></i>
                            {' Oktatói kifizetések'}
                          </Link>
                        </div>
                        <div>
                          <Link to='/Costs'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Költségek '}
                          </Link>
                        </div>
                        <div>
                          <Link to='/Income'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Bevételek '}
                          </Link>
                        </div>
                        <div>
                          <hr></hr>
                        </div>
                        <div>
                          <Link to='/price-list'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {' Árlista '}
                          </Link>
                        </div>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaReports.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaReports(!displayScriptantiaReports)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      Kimutatások
                    </button>
                    {displayScriptantiaReports && (
                      <div className='  w3-container'>
                        <Restricted to='scriptantiaReports.TeacherBalance'>
                          <div>
                            <Link to='/TeacherBalance'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Oktató egyenleg'}
                            </Link>
                          </div>
                        </Restricted>
                        <Restricted to='scriptantiaReports.StudentBalance'>
                          <div>
                            <Link to='/StudentBalance'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Diák egyenleg'}
                            </Link>
                          </div>
                        </Restricted>
                        <Restricted to='scriptantiaReports.StudentBalance'>
                          <div>
                            <Link to='/studentDashboard'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Diák egyenleginfó (új)'}
                            </Link>
                          </div>
                        </Restricted>

                        <Restricted to='scriptantiaReports.AdminReports'>
                          <div>
                            <Link to='/Results'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Eredmények'}
                            </Link>
                          </div>

                          <div>
                            <Link to='/CashFlows'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Ki- és Befizetések'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/DiakDijak'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Diák óradíjak áttekintő'}
                            </Link>
                          </div>
                          <div>
                            <Link to='/Latogatottsag'>
                              <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                              {' Óralátogatások'}
                            </Link>
                          </div>
                        </Restricted>
                      </div>
                    )}
                  </Restricted>
                  <Restricted to='scriptantiaCampaign.menu'>
                    <button
                      onClick={() =>
                        toggleScriptantiaCampaigns(!displayScriptantiaCampaigns)
                      }
                      type='button'
                      className='w3-button w3-block w3-theme-dark-orange-l3 w3-left-align'
                    >
                      <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
                      E-mail kampányok
                    </button>
                    {displayScriptantiaCampaigns && (
                      <div className='  w3-container'>
                        <div>
                          <Link to='/MailCampaign'>
                            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                            {'Kampányok kezelése'}
                          </Link>
                        </div>
                      </div>
                    )}
                  </Restricted>
                </div>
              </Fragment>
            )}
          </Restricted>
          <Restricted to='palanta.menu'>
            <button
              onClick={() => togglePalanta(!displayPalanta)}
              type='button'
              className='w3-button w3-block w3-theme-dark-orange-l4 w3-left-align'
            >
              <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
              Palánta oldal Admin
            </button>
            {displayPalanta && (
              <Fragment>
                <div className='  w3-container'>
                  <div>
                    <Link to='/palanta-blog'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Blog bejegyzések'}
                    </Link>
                  </div>
                </div>
              </Fragment>
            )}
          </Restricted>
          <Restricted to='auth.menu'>
            <button
              onClick={() => toggleAuthentication(!displayAuth)}
              type='button'
              className='w3-button w3-block w3-theme-indigo w3-left-align'
            >
              <i className='fa fa-circle-o-notch fa-fw w3-margin-right'></i>{' '}
              Jogosultságok
            </button>
            {displayAuth && (
              <Fragment>
                <div className='  w3-container'>
                  <div>
                    <Link to='/manage-users'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Felhasználók szerkesztése'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/user-roles'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Szerepkörök'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/user-rights'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Szerepkör jogosultságok'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/user-permissions'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Jogok definiálásra'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/permission-to-role'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Szerepkör funkciók'}
                    </Link>
                  </div>

                  <div>
                    <Link to='/apis'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' API definíciók'}
                    </Link>
                  </div>

                  <div>
                    <Link to='/api-rights'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' API jogosultságok'}
                    </Link>
                  </div>

                  <div>
                    <Link to='/SystemParams'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Rendszerparaméterek'}
                    </Link>
                  </div>
                  <div>
                    <Link to='/SystemSettings'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Rendszer beállítások'}
                    </Link>
                  </div>
                </div>
              </Fragment>
            )}
          </Restricted>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  userProfile: state.userProfile,
  params: state.params,
});

export default connect(mapStateToProps, {})(MainMenu);
